<template>
  <section>
    <van-nav-bar title="个人信息" left-arrow @click-left="backSpace" />
    <van-form @submit="setMember">
      <van-field
        disabled
        v-model="form.username"
        name="账号"
        label="账号"
        placeholder="账号"
      />
      <van-field
        v-model="form.realname"
        type="realname"
        name="姓名"
        label="姓名"
        placeholder="姓名"
      />
      <van-field
        v-model="form.job"
        type="job"
        name="工作单位"
        label="工作单位"
        placeholder="工作单位"
      />
      <van-field
        readonly
        clickable
        name="area"
        :value="form.area"
        label="收货地址"
        placeholder="点击选择省市区"
        @click="showArea = true"
      />
      <van-popup v-model="showArea" position="bottom">
        <van-area
          :area-list="areaList"
          @confirm="getarea"
          @cancel="showArea = false"
        />
      </van-popup>
      <van-field
        v-model="form.address"
        type="address"
        name="详细地址"
        label="详细地址"
        placeholder="详细地址"
      />
      <van-field
        v-model="form.code"
        name="发票抬头"
        label="发票抬头"
        placeholder="发票抬头"
      />
      <van-field
        v-model="form.num"
        name="税号"
        label="税号"
        placeholder="税号"
      />
      <van-field
        v-model="form.receiver_email"
        name="接收发票邮箱"
        label="接收发票邮箱"
        placeholder="接收发票邮箱"
      />
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >保存</van-button
        >
      </div>
    </van-form>
  </section>
</template>
<script>
import Vue from "vue";
import { setMember, getMemberInfo } from "@/assets/api/api";
import qs from "qs";
import { Toast } from "vant";
Vue.use(Toast);
import { Cascader } from "vant";
Vue.use(Cascader);
import { areaList } from "@vant/area-data";
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
        realname: "",
        job: "",
        area: "",
        address: "",
        receiver_email:""
      },
      showArea: false,
      areaList: areaList,
    };
  },
  computed: {},
  methods: {
    getMemberInfo() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      getMemberInfo().then((res) => {
        Toast.clear();
        if (res.data.code == 0) {
          this.form = res.data.data;
        } else {
          Toast(res.data.message);
        }
      });
    },
    getarea(values) {
      this.form.area = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;
    },
    setMember() {
      if (this.form.username == "") {
        Toast("请输入账号");
      } else if (this.form.realname == "") {
        Toast("请输入真实姓名");
      } else if (this.form.job == "") {
        Toast("请输入工作单位");
      } else if (this.form.area == "") {
        Toast("请选择收货地址");
      } else if (this.form.address == "") {
        Toast("请输入详细地址");
      } else if (this.form.receiver_email == "") {
        Toast("请输入接收发票邮箱");
      } else {
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
          loadingType: "spinner",
        });
        setMember(qs.stringify(this.form)).then((res) => {
          Toast.clear();
          if (res.data.code == 0) {
            Toast("修改成功");
          } else {
            Toast(res.data.message);
          }
        });
      }
    },
  },
  created() {},
  mounted() {
    this.getMemberInfo();
  },
};
</script>
<style scoped>
.module_data {
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 0.3rem 0.3rem 0.3rem;
}
</style>